import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ProjectLookbackOperationResponse } from "src/app/shared/models/project-lookback-operation-response";

@Injectable({
  providedIn: "root",
})
// This will be used for all API Reqs - you'll have to define the calls in the BE -Jon

// Delete isn't entirely mapped out since I was just working with creating the lookback -Jon
export class ProjectLookbackService {
  constructor(private http: HttpClient) { }

  apiRoot = `${environment.apiUrl}ProjectLookback`;

  getAllProjectLookbacks(): Observable<ProjectLookbackOperationResponse> {
    const endPoint = `${this.apiRoot}/getAllProjectLookbacks`;
    
    return this.http.get<ProjectLookbackOperationResponse>(endPoint);
  }

  getLessonsFromProjectLookback(projectCode: string): Observable<ProjectLookbackOperationResponse> {
    const endPoint = `${this.apiRoot}/getLessonsFromProjectLookback?projectCode=${projectCode}`;

    return this.http.get<ProjectLookbackOperationResponse>(endPoint);
  }

  getProjectLookback(projectCode: string): Observable<ProjectLookbackOperationResponse> {
    const endPoint = `${this.apiRoot}/getProjectLookbackByCode?projectCode=${projectCode}`;

    return this.http.get<ProjectLookbackOperationResponse>(endPoint);
  }

  createUpdateProjectLookback(body): Observable<ProjectLookbackOperationResponse> {
    const endPoint = `${this.apiRoot}/createUpdateProjectLookback`;

    return this.http.post<ProjectLookbackOperationResponse>(endPoint, body);
  }

  deleteProjectLookbacks(projectLookbackIds: string[]): Observable<ProjectLookbackOperationResponse> {
    const endPoint = `${this.apiRoot}/deleteProjectLookbacks`;
    return this.http.post<ProjectLookbackOperationResponse>(endPoint, projectLookbackIds);
  }

  shareProjectLookback(projectCode: string): Observable<ProjectLookbackOperationResponse> {
    const endPoint = `${this.apiRoot}/shareProjectLookback?projectCode=${projectCode}`;

    return this.http.post<ProjectLookbackOperationResponse>(endPoint, projectCode);
  }

}