<app-page-header [pageTitle]="pageTitle"></app-page-header>
<app-workflow-status-progress-bar [statusTitle]="statusTitle" [statuses]="projectLookbackStatuses" [currentStatus]="projectLookbackService.projectLookback.status" *ngIf="showProgressBar"></app-workflow-status-progress-bar>
<div class="w-100" *ngIf="!this.projectLookbackService.show">
  <div
    class="d-flex justify-content-center align-items-center"
    style="min-height: 600px"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="d-flex flex-row" *ngIf="this.projectLookbackService.show">
  <div class="left-ctn-container" style="width: 40%">
    <div
      *ngFor="
        let itemgroup of projectLookbackService.projectLookbackCommonService
          .visibleFieldItems;
        let i = index
      "
    >
      <div class="d-flex flex-row left-ctn">
        <mat-card class="no-outline left-ctn p-4">
          <mat-card-header class="left-ctn">
            <div
              class="d-flex flex-row justify-content-between align-items-center"
              style="width: 100%"
            >
              <div
                class="d-flex flex-column justify-content-start mb-2"
                style="width: 100%"
              >
                <div
                  class="d-flex flex-row justify-content-between align-items-center" style="margin-bottom: 1rem;"
                >
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <button
                    *ngIf="isAdmin"
                    (click)="openPopup()"
                    type="button"
                    class="btn btn-primary addAdminBtn"
                  >
                    + project admin
                  </button>
                  <div class="icons">
                    <mat-icon
                    style="cursor: pointer"
                    class="buttonHover"
                    matTooltip="share"
                    (click)="
                      $event.stopPropagation();
                      goToShare(projectLookbackService.projectLookback)
                    "
                    >share</mat-icon
                  >
                  <mat-icon
                    *ngIf="isAdmin"
                    style="color: red; cursor: pointer"
                    matTooltip="delete"
                    class="deleteIcon ml-2"
                    (click)="delete()"
                    >delete</mat-icon
                  >
                  </div>
                </div>
              </div>
            </div>
          </mat-card-header>
          <hr />
          <mat-card-content class="left-ctn">
            <div class="d-flex flex-row">
              <div class="row">
                <ng-container>
                  <div
                    class="col-6 mt-4 color-black"
                    *ngFor="let item of itemgroup.value; let j = index"
                  >
                    <form
                      [formGroup]="
                        projectLookbackService.projectLookbackFormGroup
                      "
                      *ngIf="projectLookbackService.projectLookbackFormGroup"
                    >
                      <app-project-lookback-dynamic-component
                        #p{{i}}{{j}}
                        fieldId="{{ item.key }}"
                        [controlType]="item.value.feMetadata.controlType"
                        [isVisible]="item.value.feMetadata.isVisible"
                        [labelValue]="item.value.feMetadata.formLabel"
                        [className]="
                          projectLookbackService.projectLookbackCommonService.isFieldRequired(
                            projectLookbackService.projectLookback.status,
                            item.key
                          )
                        "
                        [fieldValue]="
                          projectLookbackService.getProjectLookbackFieldValue(
                            item.key
                          )
                        "
                        [extension]="projectLookbackService.extensionType"
                        [form]="projectLookbackService.projectLookbackFormGroup"
                        [userType]="item.value.feMetadata.dataType"
                        [groupName]="itemgroup.key"
                        (onProjectLookbackChange)="
                          projectLookbackService.updateProjectLookback($event)
                        "
                        [previousProjectLookback]="
                          projectLookbackService.projectLookback
                        "
                        [isFromCreateNewProjectLookback]="false"
                        [fieldList]="
                          item.key == 'transitionName'
                            ? projectLookbackService.projectLookbackEvalService
                                .projectLookbackNextSteps
                            : projectLookbackService.projectLookbackCommonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
                                itemgroup.key,
                                item.key,
                                projectLookbackService.projectLookback
                                  .workflowType
                              )
                        "
                        (onValueChange)="
                          projectLookbackService.onValueChange(
                            $event,
                            item.key,
                            item.value.feMetadata.referenceConfigGraph
                          )
                        "
                        [isEditable]="
                          projectLookbackService.projectLookbackCommonService.isFieldEditable(
                            projectLookbackService.projectLookback
                              .isProjectLookbackEditable,
                            projectLookbackService.projectLookback.status,
                            item.key
                          )
                        "
                      >
                      </app-project-lookback-dynamic-component>
                    </form>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-card-content>
          <hr />
          <mat-card-footer class="left-ctn">
            <p class="name-label m-4">Team Comments</p>
            <div class="d-flex flex-row justify-content-between">
              <ng-container>
                <div class="d-flex flex-column align-items-left col-12">
                  <div class="d-flex justify-content-between">
                    <label [ngClass]="errorColor ? 'color-red' : 'labelFont'"
                      >optional</label
                    >
                    <div [ngClass]="errorColor ? 'color-red' : 'labelFont'">
                      {{ currentWordLength }} / {{ maximumWords }}
                    </div>
                  </div>
                  <textarea
                    class="textarea commentSection"
                    [maxlength]="maximumWords"
                    [(ngModel)]="teamComment"
                    (blur)="updateTeamComment()"
                    rows="10"
                    cols="20"
                    style="height: auto; width: 100%"
                    (ngModelChange)="checkForMaximumWordLength($event)"
                  ></textarea>
                </div>
              </ng-container>
            </div>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="right-ctn" style="width: 70%">
    <div>
      <div id="back" class="ml-5">
        <span
          class="backText"
          style="cursor: pointer"
          (click)="projectLookbackService.NavigateToDashboard()"
        >
          project lookback</span
        >
        &#62;
        <span class="highlighText ml-1"> review project lookback</span>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <div class="col-10 mt-5">
          <mat-tab-group
            [selectedIndex]="projectLookbackService.lessonTab"
            (selectedTabChange)="
              projectLookbackService.LessonTabChange($event);
              groupByDiscipline()
            "
          >
            <mat-tab
              *ngFor="let tab of tabs"
              [label]="tab.label + ' [' + tab.count + ']'"
            >
            </mat-tab>
          </mat-tab-group>
        </div>
        <div
          class="d-flex flex-column lessonButtons"
          style="gap: 1rem"
          *ngIf="lessons.length > 0"
        >
          <button
            *ngIf="overDeadline"
            (click)="projectLookbackService.NavigateToLessonPage()"
            type="button"
            class="btn btn-primary addLessonBtn"
          >
            + new lesson
          </button>
          <button
            *ngIf="overDeadline"
            type="button"
            class="btn btn-primary importLessonBtn"
            (click)="importLessons()"
          >
            import lessons
          </button>
        </div>
      </div>
      <div class="mt-5 ml-5">
        <h3 class="bucketHeader" *ngIf="data.length > 0">Please select one of the following disciplines to view all associated lessons</h3>
        <div class="d-flex flex-row noLessonsContainer" *ngIf="lessons.length == 0">
          <h3>
            Looks like there aren't any lessons yet :/
          </h3>
          <div class="noLessonButtons">
            <button
            *ngIf="overDeadline"
            (click)="projectLookbackService.NavigateToLessonPage()"
            style="height: 50px"
            type="button"
            class="btn btn-primary addLessonBtn"
          >
            + new lesson
          </button>
          <button
            *ngIf="overDeadline"
            style="height: 50px"
            type="button"
            class="btn btn-primary importLessonBtn"
            (click)="importLessons()"
          >
            import lessons
          </button>
          </div>
        </div>
        <div
          *ngIf="data.length > 0"
          class="d-flex flex-row justify-content-start"
        >
          <div *ngFor="let disc of data; let i = index">
            <div class="d-flex flex-row align-items-stretch mt-4 mr-4">
              <div>
                <ng-container class="container">
                  <mat-card
                    class="lessons-card d-flex flex-column justify-content-between"
                    style="width: 260px; height: 270px"
                    (click)="
                    projectLookbackService.NavigateToLessonDetail(
                      disc.discipline
                    )
                  "
                  >
                  <div class="color-rec">&nbsp;</div>
                    <mat-card-content>
                      <div class="d-flex flex-row">
                        <div class="row">
                          <ng-container>
                            <div class="mt-3">
                              <mat-icon style="transform: scale(1.2)"
                                >post_add</mat-icon
                              >
                              <h2 class="mt-1">{{ disc.description }}</h2>
                              <p style="font-size: 20px">
                                {{ disc.numLessons }} lessons
                              </p>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </mat-card-content>
                    <mat-card-footer class="footer">
                      <div class="d-flex flex-row justify-content-end">
                        <div
                          class="blueBox d-flex justify-content-center align-items-center"
                          (click)="
                            projectLookbackService.NavigateToLessonDetail(
                              disc.discipline
                            )
                          "
                        >
                          <mat-icon style="transform: scale(1.5)"
                            >chevron_right</mat-icon
                          >
                        </div>
                      </div>
                    </mat-card-footer>
                  </mat-card>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-right" *ngIf="isAdmin">
      <span
        class="color-label status"
        id="saveButton"
        (click)="updateProjectLookback()"
      >
        save changes
      </span>
      <button
      class="btn btn-primary"
      mat-button
      [matMenuTriggerFor]="backMenu"
      id="backButton"
      *ngIf="projectLookbackService.projectLookbackEvalService.projectLookbackPriorSteps.length > 0"
    >
      previous stage
    </button>
    <mat-menu #backMenu="matMenu">
      <button
        *ngFor="
          let actions of projectLookbackService.projectLookbackEvalService.projectLookbackPriorSteps
        "
        mat-menu-item
        (click)="confirmSubmitDialog(actions.Code)"
      >
        {{ actions.Description }}
      </button>
    </mat-menu>
      <button
        class="btn btn-primary"
        mat-button
        [matMenuTriggerFor]="submitMenu"
        *ngIf="projectLookbackService.projectLookbackEvalService.projectLookbackNextSteps.length > 0"
        id="submitButton"
      >
        next stage
      </button>

      <mat-menu #submitMenu="matMenu">
        <button
          id="menuButton"
          *ngFor="
            let actions of projectLookbackService.projectLookbackEvalService
              .projectLookbackNextSteps
          "
          mat-menu-item
          (click)="confirmSubmitDialog(actions.Code)"
        >
          {{ actions.Description }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
