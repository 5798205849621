<div class="container-fluid p-4">
  <div class="d-flex flex-row">
    <h4>add project admin</h4>
  </div>
  <p class="mt-2"> </p>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <app-autocomplete
      label="Please enter a name..."
      #toAutocomplete
      [allowMultipleSelection]="true"
      [clearOnOptionSelect]="true"
      (selectedOptionsEvent)="addUsers($event)"
    ></app-autocomplete>
  </div>
  <div class="mt-4">
    <h4>Project Admins</h4>
    <div
      *ngFor="let admin of adminName"
      class="d-flex flex-row justify-content-between"
    >
      <p>
        {{ admin.fullName }}
      </p>
      <div class="d-flex flex-row">
        <p>Admin</p>
        <mat-icon style="color: red" class="ml-2" (click)="deleteUser(admin)"
          >delete</mat-icon
        >
      </div>
    </div>
    <div
      *ngFor="let user of pendingAdmins"
      class="d-flex flex-row justify-content-between"
    >
      <p>
        {{ user.fullName }}
      </p>
      <div class="d-flex flex-row">
        <p>Pending</p>
        <mat-icon style="color: red" class="ml-2" (click)="deleteUser(user)"
          >delete</mat-icon
        >
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-end mt-4" style="gap: 1rem">
    <button
      type="button"
      mat-button
      class="btn"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <button class="btn btn-primary" id="submitButton" (click)="submit()">
      submit
    </button>
  </div>
</div>
