export class OpenAiMessage {
    timeStamp: number;
    body: string;
    isRequest!: boolean;
    constructor(body: string, isRequest: boolean) {
      this.timeStamp = Date.now();
      this.body = body;
      this.isRequest = isRequest;
    }
}
