export class ChatRequestModel {
  userQuestion: string;
  chatHistory: ChatInteraction[] = [];
}



export interface ChatInteraction {
  inputs: string;
  outputs: string;
}
