import { Injectable } from "@angular/core";
import { OpenAiMessage } from "src/app/shared/models/chatbot";
import { AthenaSearchService } from "../http/athena-search.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class ChatbotService {

    public messages: OpenAiMessage[] = [];
    public loading: boolean = false;

    constructor(
        private athenaSearchService: AthenaSearchService,
        private toastService: ToastService
        ) {}

    sendMessage(newMessage: string) {
        this.loading = true;
        this.messages.push(new OpenAiMessage(newMessage, true));
        this.athenaSearchService.sendMessage(this.messages).subscribe({
        next: (response) => {
            this.messages = response;
        },
        error: (err) => {
            this.toastService.showError(true, `Error: ${err}`)
        },
        complete: () => this.loading = false,
        });
    }
}
