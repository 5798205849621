import { BrowserAuthOptions } from "@azure/msal-browser";
const appRegistration = {
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  clientId: "92ea3dbc-1155-411c-9f1c-44dbded7af7e",
};

const msalConfigBrowserAuth: BrowserAuthOptions = {
  clientId: appRegistration.clientId,
  redirectUri: "/",
  postLogoutRedirectUri: "/",
  authority: `https://login.microsoftonline.com/${appRegistration.tenantId}/`,
};
export const appInfo = {
  contentContactName: "Chris Leinweber",
  contentContactUrl: "mailto:chris.leinweber@chevron.com",
  technicalContactName: "Surface - Team Athena",
  technicalContactUrl: "mailto:cvx_surface_ams_team@chevron.com",
  copyright: "2024",
  lastUpdate: "release/2024/24.1.IP",
  productUserGuide: "User Guide - Overview (azure.com)",
  productUserGuideUrl:
    "https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/52552/User-Guide",
  preRecordedVideoUrl:
    "https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/77564/Self-Service-Training",
  supportTicketUrl:
    "https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/65073/Create-Snow-Support-Ticket",
  feedbackUrl:
    "https://forms.office.com/Pages/ResponsePage.aspx?id=oZ15_cG_NEKpHHKzocueJpn5qZRYRTlPrC9IYa9MLOxUMDJLM1I0NjhTU1pOMlc2RzZHRFlZU1M3USQlQCN0PWcu",
  authenticationErrorUrl:
    "https://dev.azure.com/chevron/ETC-FE-EnterpriseLessonsLearned/_wiki/wikis/ETC-FE-EnterpriseLessonsLearned.wiki/118962/Troubleshooting-Common-Issues"
};
export const environment = {
  msalConfig: {
    browserAuth: msalConfigBrowserAuth,
    autoSignIn: true,
    popupForLogin: false,
    oidcScopes: ["openid", "profile", "email", "offline_access", "User.Read"],
    graphScopes: [
      "GroupMember.Read.All",
      "Directory.Read.All",
      "Group.Read.All",
      "User.Read",
      "User.Read.All",
    ],
    athenaSearchScope:["https://surbot-test.azure.chevron.com/Athena.ExternalSearch"],
    cacheLocation: "sessionStorage",
  },
  appRegistration: appRegistration,
  homeUrl: "https://ell-test.chevron.com",
  production: false,
  type: "TEST",
  groupEnv: "TEST",
  msGraphUrl: "https://graph.microsoft.com/v1.0/",
  apiUrl: "https://ell-test-api-cvx.azurewebsites.net/api/",
  athenaApiUrl: "https://athena-v2-api-dev.azure.chevron.com",
  athenaUiUrl: "https://athena-dev.azure.chevron.com",

  //Power BI reporting info
  pbiReportId: "6a9ffc5b-2c80-49b2-ae7b-ceb8858cfc55",
  pbiGroupId: "b2154035-7860-43fe-bf06-8b57971f7a38",
  pbiDatasetId: "30ab33d0-7777-4e6d-b57b-ad9381188329",

  //app insights connection string info
  appInsightUrl: "https://api.applicationinsights.io/v1/apps/",
  appInsightId: "29547b15-9bbe-4fcd-9085-0b0ab102a80e",
};
