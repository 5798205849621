import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ErrorDialogService } from "src/app/shared/components/error-dialog/error-dialog.service";

@Injectable()
export class LessonErrorHandler implements ErrorHandler {
    private urlsToIgnore: string[] = [
        "https://graph.microsoft.com/v1.0/me/photo/$value" // 404 properly returned when user picture is missing
    ];

    constructor(
        private errorDialogService: ErrorDialogService,
        private zone: NgZone
    ) { }

    handleError(err: any): void {
        if (err instanceof HttpErrorResponse && this.urlsToIgnore.includes(err.url)) {
            console.log("HTTP error ignored: ", err);
            return;
        }
        // Conditional used to enforce config files are loaded in properly after clearing browser cache (Lessons Related) - should be permanently resolved, however, leaving logic during UAT
        else if ((err.message.includes('fieldConfig') || err.message.includes('referenceConfig') || err.message.includes('ExpressionChangedAfterItHasBeenCheckedError'))){
            return;
        }
        // Conditional used to enforce config files are loaded in properly after clearing browser cache (Project Lookback Related)  - should be permanently resolved, however, leaving logic during UAT
        else if ((err.message.includes('isProjectLookbackEditable') || err.message.includes('workflowType') || err.message.includes('projectCode'))){
            return;
        }    
        else if(err instanceof HttpErrorResponse && err.message.includes('/api/User/loggedUser')){
            console.error("Looks like there was an issue with validating your authentication token. Please clear out your browser's cache and refresh your page.", err);
            this.zone.run(() => {
                this.errorDialogService.openDialog(err, err.error || "Looks like there was an issue with validating your authentication token. Please clear out your browser's cache and refresh your page.");
            });
            return;
        }
        else if(err instanceof HttpErrorResponse && err.message.includes('/oath2/v2.0/token')){
            console.error("Looks like there was an issue with validating your authentication token. Please clear out your browser's cache and refresh your page.", err);
            this.zone.run(() => {
                this.errorDialogService.openDialog(err, err.error || "Looks like there was an issue with validating your authentication token. Please clear out your browser's cache and refresh your page.");
            });
            return;
        }
        else if(err.message.includes('no_tokens_found')){
            console.error("Looks like there was an issue with validating your authentication token. Please clear out your browser's cache and refresh your page.", err);
            this.zone.run(() => {
                this.errorDialogService.openDialog(err, err.error || "Looks like there was an issue with validating your authentication token. Please clear out your browser's cache and refresh your page.");
            });
            return;
        }
        else if(err.message.includes('monitor_window_timeout')){
            window.location.reload();
            return;
        }

        this.zone.run(() => {
            this.errorDialogService.openDialog(err, err.error || "We're unable to pinpoint the exact error. Please open a ticket or contact us so we can resolve it!");
        });
        console.error("Error from global error handler", err);
    }
}
