<div
  class="row addAttachment"
  (click)="fileInputNew.click()"
  *ngIf="svcAttachment.selectedFiles.length > 0"
>
  <mat-icon>add_circle</mat-icon><span>add an attachment</span>
</div>
<div
  class="row attachmentViewTable"
  *ngIf="svcAttachment.selectedFiles.length > 0"
>
  <table
    mat-table
    [dataSource]="svcAttachment.dataSource"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="fileIcon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <img
          class="fileIconSmall"
          src="{{ getFileIcon(element.fileName) }}"
          alt=""
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ removeExtensionForAttachmentName(element.fileName) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action" *ngIf="isParticipant">
      <th mat-header-cell *matHeaderCellDef class="contextMenuHeader"></th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        class="contextMenuCell"
        (click)="onContextMenu($event, i, element)"
      >
        ...
      </td>
    </ng-container>
    <ng-container matColumnDef="action" *ngIf="!isParticipant">
      <th mat-header-cell *matHeaderCellDef class="contextMenuHeader"></th>
      <td
        id="disabled3dots"
        mat-cell
        *matCellDef="let element; let i = index"
        class="contextMenuCell"
        (click)="onContextMenu($event, i, element)"
      >
        ...
      </td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Size</th>
      <td mat-cell *matCellDef="let element">{{ element.strFileSize }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>DateAttached</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastUpdatedDate | date : "MMMM dd, yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="attachedBy">
      <th mat-header-cell *matHeaderCellDef>Attached By</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastUpdatedUser?.fullName }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="svcAttachment.columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: svcAttachment.columnsToDisplay"
    ></tr>
  </table>
  <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
  ></div>
  <mat-menu #contextMenu="matMenu" #contextMenu2="matMenu">
    <ng-template matMenuContent let-item="item">
      <button
        mat-menu-item
        (click)="onContextMenuDownload(item)"
        class="contextMenuItem"
      >
        <mat-icon class="contextMenuItemIcon">get_app</mat-icon> download
      </button>
      <button
        mat-menu-item
        (click)="onContextMenuDelete(item)"
        class="contextMenuItem"
        [disabled]="disbaleDeleteAttachment(item)"
      >
        <mat-icon class="contextMenuItemIcon">clear</mat-icon> delete
      </button>
    </ng-template>
  </mat-menu>
</div>
<div
  class="row"
  id="attachmentSection"
  style="cursor: pointer"
  *ngIf="svcAttachment.selectedFiles.length == 0"
>
  <div class="row col-12 mainAttachmentSection" (click)="fileInputNew.click()">
    <div class="col-1">
      <img class="fileIcon" src="assets/icons/Icons - Document.svg" alt="" />
    </div>
    <div class="col-10" id="Addsection">
      <h2>Add Attachment</h2>
      <p>You can upload any .PDF, doc, xls, ppt or image</p>
    </div>
  </div>
</div>
<input
  #fileInputNew
  class="form-control"
  type="file"
  hidden
  accept="application/pdf,
                   application/vnd.ms-excel,
                   application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                   application/msword,
                   application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                   application/vnd.ms-powerpoint,
                   application/vnd.openxmlformats-officedocument.presentationml.presentation,
                   image/jpeg,
                   image/png"
  (change)="chooseFile(fileInputNew.files)"
/>
