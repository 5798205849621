import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtensionType } from 'src/app/config/global-enums.config';
import { ProjectLookbackService } from 'src/app/core/http/project-lookback.service';
import { CreateEditProjectLookbackService } from 'src/app/core/services/project-lookback-services/create-edit-project-lookback.service';
import { ProjectLookbackCommonService } from 'src/app/core/services/project-lookback-services/project-lookback-common.service';
import { ProjectLookback } from 'src/app/shared/models/project-lookback';
import { LessonService } from 'src/app/core/http/lesson.service';
import { Lesson, User } from 'src/app/shared/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Facet } from 'src/app/shared/models/search-options';
import { ToastService } from 'src/app/core/services/toast.service';
import { Statuses } from 'src/app/config/global-enums.config';
import { LessonStatus, LessonTabStatus } from 'src/app/shared/models/project-lookback-status';
import { firstValueFrom, Subscription } from "rxjs";
import { DialogMessageComponent } from "src/app/modules/home/dialogs/dialog-message/dialog-message.component";
import { MatDialog } from "@angular/material/dialog";
import { AddAdminComponent } from '../../../dialogs/add-admin/add-admin.component';
import { UserService } from 'src/app/core/http/user.service';
import { ShareProjectLookbackComponent } from '../../../dialogs/share-project-lookback/share-project-lookback.component';
import { P } from '@angular/cdk/keycodes';
import { ImportLessonModalComponent } from '../../home/import-lesson-modal/import-lesson-modal.component';
import { ProjectLookbackEvalConfigService } from 'src/app/core/services/project-lookback-services/project-lookback-eval-config.service';

@Component({
  selector: 'app-project-lookback-bucket',
  templateUrl: './project-lookback-bucket.component.html',
  styleUrls: ['./project-lookback-bucket.component.scss']
})
export class ProjectLookbackBucketComponent implements OnInit{
  public tabs = Object.values(LessonTabStatus).map((status) => ({
      key: status,
      label: status,
      count: 0
  }));

  data: Discipline[] = [];
  lessons: Lesson[] = [];
  facets: Facet[] = []
  overDeadline: boolean = false;

  teamComment: string = "";
  currentWordLength: number = 0;
  maximumWords: number = 250;
  errorColor: boolean = false;
  isAdmin: boolean = false;
  userProfile: User;
  pageTitle = "";
  statusTitle = "";
  projectLookbackStatuses: any;
  showProgressBar: boolean = false;

  constructor(
    public projectLookbackService: CreateEditProjectLookbackService,
    public projectLookbackCommonService: ProjectLookbackCommonService,
    private projectLookbackApiService: ProjectLookbackService,
    private projectLookbackEvalService: ProjectLookbackEvalConfigService,
    private userService: UserService,
    private toastService: ToastService,
    public router: Router,
    private lessonService: LessonService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ){}

  async ngOnInit() {
    this.projectLookbackService.show = false;
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.isAdmin = this.projectLookbackCommonService.getAdminStatus(this.userProfile);
    await this.projectLookbackService.checkForPlb();
    this.projectLookbackService.getProjectLookbackConfigs();
    this.projectLookbackService.projectLookback.isProjectLookbackEditable = true;
    await this.getLessonsForPLB();

    this.projectLookbackStatuses = this.projectLookbackEvalService.getProjectLookbackWorkflowStatuses(this.projectLookbackService.projectLookback.workflowType);
    if(this.projectLookbackStatuses){
      let currentStatusIndex = this.projectLookbackStatuses.findIndex(status => status === this.projectLookbackService.projectLookback.status);
      const stages = `${currentStatusIndex + 1}/${this.projectLookbackStatuses.length}`;
      this.statusTitle = `Project Lookback Progression - Stage: (${stages})`;
      this.showProgressBar = true;
    }
    
    this.teamComment = this.projectLookbackService.projectLookback.teamComments;
    this.overDeadline = new Date(this.projectLookbackService.projectLookback.projectDeadline) > new Date();
    this.pageTitle = `Project Lookback Bucket - Code: ${this.projectLookbackService.projectLookback.projectCode}`
  }

  async getLessonsForPLB(){
    if(!this.projectLookbackService.projectLookback?.projectCode)
    {
      this.projectLookbackService.NavigateToDashboard();
      return
    }
    this.lessonService.getProjectLookbackLessons(this.projectLookbackService.projectLookback.projectCode).subscribe({
      next: (result) => {
        this.lessons = result.lessons;
        this.groupByDiscipline();
        this.getLessonCounts();
        this.projectLookbackService.show = true;
      
      }, error: (err) => {
        this.snackBar.open(err);
      }
    })
  }

  public confirmSubmitDialog(selectedAction: string) {
    this.projectLookbackService.projectLookback.transitionName = selectedAction;
    this.onTransitionNameSelected(selectedAction);
  }

  onTransitionNameSelected(selectedTransitionName: any) {
    if (selectedTransitionName) {
      this.projectLookbackService.projectLookback.pendingStatus =
        this.projectLookbackCommonService.getPendingStatus(
          selectedTransitionName,
          this.projectLookbackService.projectLookback.workflowType,
          this.projectLookbackService.projectLookback.status
        );

      this.validateLessonsForPlbTransition(this.projectLookbackService.projectLookback.pendingStatus);
    }
  }

  delete(){
    const projectLookbackIdsToDelete: Array<string> = [];
    projectLookbackIdsToDelete.push(this.projectLookbackService.projectLookback.projectId);
    const body = 'Are you sure you want to delete this project lookback?';
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      panelClass:"dialog",
        width: "600px",
        data: { title: "delete project lookback?", body },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        try{
          this.projectLookbackService.deleteProjectLookback(projectLookbackIdsToDelete).then(
            (_) => {this.projectLookbackService.NavigateBackToHomePage();}
          );
          this.toastService.showSuccess(true, 'Successfully deleted the project lookback!');
        }
        catch(error){
          this.toastService.showError(true, "Uh-oh! Looks like there was an issue while trying to delete the project lookback. Please reach out to the support team for further assistance.");
        }
      }
    });
  }

  validateLessonsForPlbTransition(projectLookbackStatus){
    if(projectLookbackStatus === 'completed'){
      this.toastService.showSuccess(true, 'Successfully transitioned the project lookback!');
      this.lessons.forEach((lesson) => {
        lesson.lessonWorkflowType = this.projectLookbackService.projectLookback.lessonExtensionType;
        if(lesson.status === 'review'){
          this.toastService.showError(true, 'Uh-oh! Please ensure that all lessons within the project lookback are either approved or rejected before moving to completion.');
          this.saveOrSubmitProjectLookback(false);
        }
        else if(lesson.status === 'rejected'){
          lesson.status = Statuses.Rejected;
          firstValueFrom(this.lessonService.createUpdateLesson(lesson)).then(
            (result) => {
            });
          this.saveOrSubmitProjectLookback(true);
        }
        else if(lesson.status === 'approved' || lesson.status === 'publish'){
          lesson.status = Statuses.Publish;
          firstValueFrom(this.lessonService.createUpdateLesson(lesson)).then(
            (result) => {
            });
          this.saveOrSubmitProjectLookback(true);
        }
      })
    }
    else{
      this.toastService.showSuccess(true, 'Successfully transitioned the project lookback!');
      this.saveOrSubmitProjectLookback(true);
    }
  }

  importLessons() {
    const dialogRef = this.dialog.open(ImportLessonModalComponent, {
      width: "1150px",
      data: {
        workflow: this.projectLookbackService.projectLookback.lessonExtensionType,
        projectCode: this.projectLookbackService.projectLookback.projectCode,
        businessUnit: this.projectLookbackService.projectLookback.businessUnit
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res && res.success)
      {
        this.projectLookbackService.show = false;
        this.lessons.push(...res.lessons);
        this.groupByDiscipline();
        this.getLessonCounts();
        this.projectLookbackService.show = true;
        
      }
     });
  }

  async updateProjectLookback(){
    await firstValueFrom(this.projectLookbackApiService.createUpdateProjectLookback(this.projectLookbackService.projectLookback)).then(
      (res) => {
        if(res.success){
          this.toastService.showSuccess(true, 'project lookback updated!');
        }
        else{
          this.toastService.showError(true, "Uh-oh! Looks like there was an issue updating the project lookback. Please reach out to the support team for further assistance.")
        }
      }
    )
  }
  saveOrSubmitProjectLookback(isSubmit: boolean, isSave?: boolean){
    this.projectLookbackService.saveOrSubmitProjectLookback(isSubmit);
  }

  getLessonCounts() {
    this.tabs.forEach(tab => {
      let count = 0;
      if (tab.key == "all")
      {
        count  =this.lessons.length;
      }
      else {
        count = this.lessons.filter(lesson => lesson.status == tab.label).length;
        
      }
      tab.count = count;
    })
  }

  groupByDiscipline() {
    let filteredLessons = [];
    let currentStatus = this.projectLookbackService.enumMapLesson[this.projectLookbackService.lessonTab];
    if (currentStatus == "all")
    {
      filteredLessons = this.lessons;
    }
    else {
      filteredLessons  = this.lessons.filter(x => x.status == currentStatus && x.discipline == this.projectLookbackService.choosingDiscipline);
    }
    let temp = this.groupBy(filteredLessons, (e: Lesson) => e.discipline);
    let disciplineCore = this.projectLookbackCommonService.referenceConfig.core['Discipline']
    this.data = [];
    temp.forEach((e) => {
      let tempDisc: Discipline = new Discipline(e[0], e[1])
      
      let indx = disciplineCore.findIndex(x => x.Code == e[0]);
      tempDisc.description = disciplineCore[indx]?.Description ?? "No Discipline Assigned";
      this.data.push(
        tempDisc
      )
    })
  }

  private groupBy(list: any[], prop: any): any[] {
    const map = new Map();
    list.forEach((item) => {
      const key = prop(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Array.from(map);
  }

  checkForMaximumWordLength(event) {
  // Split the input into words and count them
    this.currentWordLength = event.length;
    

    if (this.currentWordLength == this.maximumWords) {
      this.errorColor = true;
    } else {
      this.errorColor = false;
    }
  }

  updateTeamComment() {
    if (this.projectLookbackService.projectLookback.teamComments != this.teamComment)
    {
      this.projectLookbackService.projectLookback.teamComments = this.teamComment;
      this.projectLookbackService.updateProjectLookbackCommentsAsync();
    }

  }

  // Going to hide until fully mapped out
  openPopup(){
    let user = new User();
    const dialogRef = this.dialog.open(AddAdminComponent, {
        data: {
          currentAdmin: this.projectLookbackService.projectLookback.projectAdmins,
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result && result.update){
          this.projectLookbackService.projectLookback.projectAdmins = [...result.data];
          this.projectLookbackService.projectLookback.projectAdmins.forEach(admin => {
            this.userService.getUserByUniqueKey(admin.uniqueKey).subscribe(userDetails => {
              if(userDetails === null)
              {
                this.toastService.showError(true, "Uh-oh! We were unable to find the following user.")
              }
              else if(!this.projectLookbackService.projectLookback.projectAdmins.includes(userDetails)){
                userDetails.projectCodes = userDetails?.projectCodes || [];
                userDetails.projectCodes.push(this.projectLookbackService.projectLookback.projectCode);
                this.userService.updateProjectLookbackUser(userDetails).subscribe( resultingUser => {
                  if(resultingUser){
                    if(!this.projectLookbackService.projectLookback.participants.find(t => t.uniqueKey == resultingUser.uniqueKey)){
                      this.toastService.showSuccess(true, 'project admin added!');
                      this.projectLookbackService.projectLookback.participants.push(resultingUser);
                      this.projectLookbackService.projectLookback.userCount = this.projectLookbackService.projectLookback.participants.length;
                      this.projectLookbackApiService.createUpdateProjectLookback(this.projectLookbackService.projectLookback).subscribe(res => {
                        if(res.success){
                          this.toastService.showSuccess(true, 'project lookback updated!');
                        }
                        else{
                          this.toastService.showError(true, "Uh-oh! Looks like there was an issue with updating project admins. Please reach out to the support team for further assistance.");
                        }
                      })
                    }
                  }
                });
              }
            });
          })
        }
      });
  }
  
  goToShare(plb: ProjectLookback) {
    const dialogRef = this.dialog.open(ShareProjectLookbackComponent, {
      width: "40%",
      position: {},
      autoFocus: false,
      minHeight: "40vh", // you can adjust the value as per your view,
      data: {
        code: plb.projectCode,
        admin: plb.projectAdmins,
        participant: plb.participants,
        pendingParticipants: plb.pendingParticipants
      },
    });
  }

}



export class Discipline {
  discipline: string
  description: string
  numLessons: number
  lessons: Lesson[]

  constructor(discipline: string, lessons: Lesson[]){
    this.discipline = discipline;
    this.lessons = lessons;
    this.numLessons = this.lessons.length;
  }
}