import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from "@angular/forms";
import { HighlightTag } from "angular-text-input-highlight";
import { ProjectLookbackCommonService } from "src/app/core/services/project-lookback-services/project-lookback-common.service";
import { CreateEditProjectLookbackService } from "src/app/core/services/project-lookback-services/create-edit-project-lookback.service";
import { Reference, User,  } from "src/app/shared/models";
import { ProjectLookback, ProjectLookbackFieldUpdate } from "src/app/shared/models/project-lookback";

@Component({
  selector: 'app-project-lookback-dynamic-component',
  templateUrl: './project-lookback-dynamic-component.component.html',
  styleUrls: ['./project-lookback-dynamic-component.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectLookbackDynamicComponentComponent implements OnInit {
  public projectLookback: ProjectLookback = new ProjectLookback();
  public bindingModel: any;
  isUser: boolean = false;
  users: User[] = [];

  @Input() fieldId: string;
  @Input() fieldValue: any;
  @Input() fieldList: Reference[];
  @Input() className: string;
  @Input() showLabel: boolean;
  @Input() labelValue: string;
  @Input() ifTooltip: boolean;
  @Input() toolTipInstruction: string;
  @Input() previousProjectLookback: ProjectLookback;
  @Input() groupName: string;
  @Input() isEditable: boolean;
  @Input() isVisible: boolean;
  @Input() columnsWidth: number;
  @Input() formFieldClass: string = "";
  @Input() controlType: string;
  @Input() form: FormGroup;
  @Input() fieldDictatingForCrossReferenceConfig: string;
  @Input() crossReferenceConfigEnum: string;
  @Input() extension: string;
  @Input() userType: string;
  @Input() isRemoveCurrentSelection: boolean = false;
  @Input() isFromCreateNewProjectLookback: boolean;



  fieldMetadata = new ProjectLookbackFieldUpdate();
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();
  @Output() onProjectLookbackChange: EventEmitter<ProjectLookbackFieldUpdate> = new EventEmitter();

  constructor (
    public projectLookbackService: CreateEditProjectLookbackService,
    public projectLookbackCommonService: ProjectLookbackCommonService
  ){}

  async ngOnInit(): Promise<void> {
    this.projectLookback = this.previousProjectLookback;

    this.bindingModel = this.projectLookback;

    this.setUserType();

    this.fieldMetadata.projectLookback = this.projectLookback;
    this.fieldMetadata.fieldId = this.fieldId; 
  }

  valueSelected(event: any) {
    let tempProjectLookback = new ProjectLookbackFieldUpdate();
    tempProjectLookback.projectLookback = this.projectLookback;
    tempProjectLookback.fieldId = this.fieldId;


    if (event.value != undefined) this.onValueChange.emit(event);
    this.onProjectLookbackChange.emit(tempProjectLookback);
  }

  setUserType() {
    if (this.userType == "User" || this.userType == "User[]") {
      this.isUser = true;
    }
  }
}